import * as actions from './actionTypes/companies.actionType';

export function getCompaniesRequest(productId: number, page: number, pageSize: number): actions.IGetCompanies {
    return {
        type: actions.GET_COMPANIES,
        productId,
        page,
        pageSize
    };
}

export function getCompaniesSuccess(companies: Array<any>, count: number): actions.IGetCompaniesSuccess {
    return {
        type: actions.GET_COMPANIES_SUCCESS,
        companies,
        count
    };
}

export function getCompaniesError(error: Error | string): actions.IGetCompaniesError {
    return {
        type: actions.GET_COMPANIES_ERROR,
        error
    };
}

export function getCompanyFeaturesRequest(productId: number, companyId: number): actions.IGetCompanyFeatures {
    return {
        type: actions.GET_COMPANY_FEATURES,
        productId,
        companyId
    };
}

export function getCompanyFeaturesSuccess(features: Array<any>): actions.IGetCompanyFeaturesSuccess {
    return {
        type: actions.GET_COMPANY_FEATURES_SUCCESS,
        features
    };
}


export function getCompanyFeaturesError(error: Error | string): actions.IGetCompanyFeaturesError {
    return {
        type: actions.GET_COMPANY_FEATURES_ERROR,
        error
    };
}


