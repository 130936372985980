import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { Drawer } from './DrawPanelStyled';

export interface IDrawerPanelProps {
  isOpen: boolean;
  anchor?: 'left' | 'top' | 'right' | 'bottom';
  children?: React.ReactNode;
  onClose?: () => void;
}

const DrawerPanel: React.FunctionComponent<IDrawerPanelProps> = (props) => {
  const { anchor, isOpen } = props;
  return (
    <Drawer anchor={anchor}
      variant="permanent"
      ModalProps={{
        BackdropProps: {
          invisible: true
        }
      }}
      open={isOpen}
    >
      {!!props.onClose && <Box textAlign="right">
        <IconButton aria-label="close drawer" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Box>}
      {props.children}
    </Drawer>
  );
};

export default DrawerPanel;
