import jwtDecode from 'jwt-decode';
import type { TokenContent, User } from "./User";

function getFirstLetter(word: string): string {
  return word.substring(0, 1).toUpperCase();
}

export function getInitials(firstName?: string, lastName?: string): string {
  if (!firstName || !lastName) {
    return "";
  }
  const firstNameInitial = getFirstLetter(firstName);
  const lastNameInitial = getFirstLetter(lastName);
  return firstNameInitial.concat(lastNameInitial);
}

const mapUser = (tokenContent: TokenContent): User => {
  const { entls, uid, udt } = tokenContent;
  const { SF } = entls;
  const { sfcid, pcds } = SF;

  const subscriptions = pcds.map((e) => e.ecodes).flat();

  return {
    id: uid,
    salesForceId: sfcid,
    firstName: udt.fn,
    lastName: udt.ln,
    email: udt.em,
    roles: [], // api source
    features: [], // api source
    initials: getInitials(udt.fn, udt.ln),
    subscriptions,
    organizationName: udt.cmp,
  };
};

export function getTokenContent(token: string): TokenContent | null {
  return token ? jwtDecode<TokenContent>(token) : null;
}

export const getUser = (token?: string): User | undefined => {
  const userToken = token ?? localStorage.getItem("token");
  const content = userToken ? getTokenContent(userToken) : undefined;
  return content ? mapUser(content) : undefined;
};
