import { api } from '../api';

export interface IUsersResponse {
    users: Array<any> | Error;
}

export async function fetchUsers(productId: number): Promise<IUsersResponse> {
    return await api.users.getByProductId(productId);
}

export async function assignRole(userId: string, productId: number, body: any): Promise<any> {
    return await api.users.assignRole(userId, productId, body);
}

export async function getAllUsers(productId: number, filter: string) {
    return await api.users.getAllUsers(productId, filter);
}

export async function getUserFeatures(productId: number, userId: number) {
    return await api.users.getUserFeatures(productId, userId);
}

export async function updateUserFeatures(productId: number, userId: number, features: any[]) {
    return await api.users.updateUserFeatures(productId, userId, features);
}