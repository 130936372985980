import { AppState } from '../reducers/root.reducer';

const emptyUsersManagementList: Array<any> = [];
const emptyFeaturesList: Array<any> = [];

export const getAllUsers = (state: AppState): Array<any> => (state.usersData || {}).users || emptyUsersManagementList;
export const getUsersFetchError = (state: AppState): boolean => !!state.usersData?.error;
export const getAreUsersLoading = (state: AppState): boolean => (state.usersData || {}).userManagementTableLoading;

export const getUserFeatures = (state: AppState): Array<any> => (state.usersData || {}).features || emptyFeaturesList;
export const getFeaturesLoading = (state: AppState): boolean => (state.usersData || {}).areFeaturesLoading;
export const featureFetchHasError = (state: AppState): boolean => !!state.usersData?.featuresFetchError;