import * as actions from '../actions/actionTypes/features.actionType';

export interface IFeaturesState {
    productId?: number;
    areFeaturesLoading: boolean;
    featuresFetchError?: Error | string;
    features: Array<any>;
}

const initialState: IFeaturesState = {
    productId: undefined,
    areFeaturesLoading: false,
    featuresFetchError: undefined,
    features: [],
};

export default function features(state: IFeaturesState = initialState, action: actions.FeaturesAction): IFeaturesState {
    switch (action.type) {
        case actions.GET_FEATURES:
            return {
                ...state,
                productId: action.productId,
                features: [],
                areFeaturesLoading: true,
                featuresFetchError: undefined
            };
        case actions.GET_FEATURES_SUCCESS:
            return {
                ...state,
                features: action.features,
                areFeaturesLoading: false
            };
        case actions.GET_FEATURES_ERROR:
            return {
                ...state,
                features: [],
                featuresFetchError: action.error,
                areFeaturesLoading: false
            };
        default:
            return state;
    }
}