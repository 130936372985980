import * as actions from '../actions/actionTypes/roles.actionType';

export interface IRolesState {
    productId?: number;
    roles?: any;
    user?: any;
    error?: Error | string;
}

const initialState: IRolesState = { productId: undefined, roles: [], error: undefined };

export default function roles(state: IRolesState = initialState, action: actions.RoleAction): IRolesState {
    switch (action.type) {
        case actions.GET_ROLES:
            return {
                ...state,
                productId: action.productId
            };
        case actions.GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.roles
            };
        case actions.GET_ROLES_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}