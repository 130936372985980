import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';
import { GENERIC_ERROR_MESSAGE } from '../../constants/text';

export interface IGenericErrorProps {
    message?: string;
    textVariant?: Variant | 'inherit';
}

const GenericError: React.FunctionComponent<IGenericErrorProps> = (props) => {
    return (
        <Box display="flex" justifyContent="center">
            <Typography  data-testid="generic-error" variant={props.textVariant || 'h6'}>{props.message || GENERIC_ERROR_MESSAGE}</Typography>
        </Box>
    );
};

export default GenericError;
