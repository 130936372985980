import React, { FunctionComponent} from 'react';
import SaveIcon from '@mui/icons-material/Save';
import {
    Button,
    CircularProgress,
    Box,
    Typography,
    FormGroup,
} from '@mui/material';
import GenericError from '../Error/Error';
import styles from './FeatureListDrawerManagement.module.scss';
import { SelectChangeEvent } from '@mui/material/Select';
import SelectBox from '../SelectBox/SelectBox';

export interface IFeatureManagement {
    id: number;
    name: string;
    isEnabled: boolean | undefined;
}

export interface IFeatureListDrawerManagementProps {
    features: Array<IFeatureManagement>;
    entity?: any;
    error?: boolean;
    featuresLoading: boolean;
    handleNewFeatures: (data: IFeatureManagement[]) => void;
    onFormSubmit: (data: any) => any;
    onCancel: () => void;
    isConfirmed: boolean;
}

const FeatureListDrawerManagement: FunctionComponent<IFeatureListDrawerManagementProps> = (props) => {

    const handleChange = (id: number, event: SelectChangeEvent) => {

        const newFeatures = [...props.features];
        const index = newFeatures.findIndex(x => x.id === id);
        const valSelected =  event.target.value as string;
        const isEnabled = valSelected === '0' ? false : valSelected ==='1' ? true : undefined
        newFeatures[index] = { ...newFeatures[index], isEnabled }
        props.handleNewFeatures(newFeatures);
      };

    const selectBoxes = (props.features || []).map((feature: any) => {
        return (
            <div key={`${feature.id}-${props.entity?.id}`} className={styles.selectbox}>
              <SelectBox feature={feature} handleChange={handleChange}/>
            </div>
        )
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await props.onFormSubmit(props.features);
    };

    if (props.error) {
        return <GenericError />
    }

    if (!props.entity) {
        return null;
    }

    if (props.featuresLoading) {
        return (
            <Box display="flex" alignItems="center" flexDirection="column">
                <Typography data-testid="loading-feature-id" gutterBottom>Loading features...</Typography>
                <CircularProgress color="primary" />
            </Box>
        );
    }

    const title = props.entity?.displayName?props.entity.displayName:props.entity.accountName;

    return (
        <form name="featuresForm"
            noValidate
            onSubmit={handleSubmit}
            className={styles.form}
            data-testid="features-form"
        >
            <Typography
                variant="h5"
                gutterBottom
                data-testid="entity-name"
            >{title}</Typography>
            <div className={styles.table}>
                <div className={styles.heading}>Available</div>
                <FormGroup className={styles.selectboxList}>
                    <div>
                        {selectBoxes}
                    </div>
                </FormGroup>
            </div>

            <Button
                variant="contained"
                color="primary"
                type="submit"
                data-testid="save-btn"
                startIcon={ props.isConfirmed ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
            >
                Update availability
            </Button>
            <Button
                color="inherit"
                variant="contained"
                onClick={props.onCancel}
                data-testid="cancel-btn"
            >
                Cancel
            </Button>

        </form>
    );
};

export default FeatureListDrawerManagement;
