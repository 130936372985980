import React, { FunctionComponent } from 'react';
import type { } from '@mui/x-data-grid/themeAugmentation';
import { getCompaniesRequest, getCompanyFeaturesRequest } from '../../core/actions/companies.actions';
import { GridColumns } from '@mui/x-data-grid';
import { getCompanies, getIsTableLoading, getCompanyFeatures } from '../../core/selectors/companyFeatureManagementTable.selectors';
import { updateCompanyFeatures } from '../../core/services/companies.service';
import { FEAT_MANAGEMENT_COMPANY } from '../../constants/text';
import CompanyUserManagement, { EntityEnum } from '../CompanyUserManagement/CompanyUserManagement';

const COMPANIES_TABLE_PAGESIZE = 10;

export interface ICompanyFeatureManagementProps { }

const CompanyFeatureManagement: FunctionComponent<ICompanyFeatureManagementProps> = (props) => {

    const columns: GridColumns =
        [
            {
                headerName: 'Company Name',
                field: 'accountName',
                flex: 1.5,
                sortable: false,
                disableColumnMenu: true,
                type: 'string'
            },
            {
                headerName: 'License Type',
                field: 'licenseType',
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                type: 'string'
            },
            {
                headerName: 'Parent Service Id',
                field: 'parentServiceId',
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                type: 'string'
            },
            {
                headerName: 'Start Date',
                field: 'startDate',
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                type: 'string'
            },
            {
                headerName: 'End Date',
                field: 'endDate',
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                type: 'string'
            },
        ];

    return (
        <CompanyUserManagement
            type={EntityEnum.Company}
            label={"companies"}
            getEntities={getCompanies}
            getEntityLoading={getIsTableLoading}
            entityFirstState={{ accountName: '', licenseType: '', parentServiceId: '', startDate: '', endDate: '' }}
            getAllEntitiesRequest={getCompaniesRequest}
            getEntityFeatures={getCompanyFeatures}
            getEntityFeaturesRequest={getCompanyFeaturesRequest}
            updateEntityFeatures={updateCompanyFeatures}
            columns={columns}
            gridTitle={FEAT_MANAGEMENT_COMPANY}
            tablePageSize={COMPANIES_TABLE_PAGESIZE}
            rowsPerPage={[COMPANIES_TABLE_PAGESIZE]}
        />
    );

}

export default CompanyFeatureManagement;