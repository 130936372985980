import { put, call, takeEvery, all, fork } from 'redux-saga/effects';
import * as actions from '../actions/companies.actions';
import * as actionTypes from '../actions/actionTypes/companies.actionType';
import { getCompaniesPaged, getCompanyFeatures } from '../services/companies.service';

function* onLoadCompanies({ productId, page, pageSize }: actionTypes.IGetCompanies) {
    try {
        const { data } = yield call(getCompaniesPaged, productId, page, pageSize);
        yield put(actions.getCompaniesSuccess(data.companies, data.count));
    } catch (error) {
        console.log(error)
        yield put(actions.getCompaniesError(error?.response?.data?.error || error));
    }
}

function* onLoadCompanyFeatures({ productId, companyId }: actionTypes.IGetCompanyFeatures) {
    try {
        const { data } = yield call(getCompanyFeatures, productId, companyId);
        yield put(actions.getCompanyFeaturesSuccess(data));
    } catch (error) {
        yield put(actions.getCompanyFeaturesError(error?.response?.data?.error || error));
    }
}

function* watchOnLoadCompanies() {
    yield takeEvery(actionTypes.GET_COMPANIES, onLoadCompanies);
}

function* watchOnLoadCompanyFeatures() {
    yield takeEvery(actionTypes.GET_COMPANY_FEATURES, onLoadCompanyFeatures);
}

export default function* usersSaga() {
    yield all([
        fork(watchOnLoadCompanies),
        fork(watchOnLoadCompanyFeatures)
    ]);
}
