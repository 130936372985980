export const GET_PRODUCTS = "PRODUCTS/GET_PRODUCTS";
export interface IGetProducts {
    type: typeof GET_PRODUCTS;
}

export const GET_PRODUCTS_SUCCESS = "PRODUCTS/GET_PRODUCTS_SUCCESS";
export interface IGetProductsSuccess {
    type: typeof GET_PRODUCTS_SUCCESS;
    products: Array<any>;
}

export const GET_PRODUCTS_ERROR = "PRODUCTS/GET_PRODUCTS_ERROR";
export interface IGetProductsError {
    type: typeof GET_PRODUCTS_ERROR;
    error: Error | string;
}

export const SET_SELECTED_PRODUCT = "PRODUCTS/SET_SELECTED_PRODUCT";
export interface ISelectedProduct {
    type: typeof SET_SELECTED_PRODUCT;
    product?: any;
}

export type ProductAction =
    | IGetProducts
    | IGetProductsSuccess
    | IGetProductsError
    | ISelectedProduct;