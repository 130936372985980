import { Theme } from "@mui/material";
import { SxProps } from '@mui/system';

export const toolBarStyles: Record<string, SxProps<Theme>> = {
    root: {
        paddingRight: 24,
        justifyContent: 'space-between'
    },
    middleSection: {
        flexGrow: 1,
        textAlign: 'center'
    }
}