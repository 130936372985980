import type { Auth0ProviderOptions } from "@auth0/auth0-react";

export const authorizationConfig: Auth0ProviderOptions = {
  authorizationParams: {
    audience: "https://study-feasibility/api",
    redirect_uri: window.location.origin,
    scope: "openid profile email",
  },
  cacheLocation: "localstorage",
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  useCookiesForTransactions: true,
};

export const profileDomain = authorizationConfig.domain.replace("auth", "sso");

export const getMyProfileLink = (): string => {
  const source = encodeURIComponent(`${window.location.origin}/updateprofile`);
  return `${profileDomain}?source=${source}`;
};

const getUserList = (users?: string): string[] => {
  return (users ?? "").split(";").map((email) => email.toLowerCase().trim());
};

export const allowedAccessUserList: string[] = getUserList(
  process.env.REACT_APP_ALLOWED_USERS_LIST
);
