import { all, fork } from 'redux-saga/effects';
import usersSaga from './users.saga';
import rolesSaga from './roles.saga';
import productsSaga from './products.saga';
import companiesSaga from './companies.saga';
import featuresSaga from './features.saga'

export default function* rootSaga() {
    yield all([fork(usersSaga)]);
    yield all([fork(rolesSaga)]);
    yield all([fork(productsSaga)]);
    yield all([fork(companiesSaga)]);
    yield all([fork(featuresSaga)]);
}
