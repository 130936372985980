import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { Typography, Grid, Input } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColumns } from '@mui/x-data-grid';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getFeaturesLoading as getFeaturesLoadingUser, featureFetchHasError as getfeatureFetchHasErrorUser } from '../../core/selectors/users.selector';
import { getFeaturesLoading as getFeaturesLoadingCompany, featureFetchHasError as getfeatureFetchHasErrorCompany, getCompaniesCount} from '../../core/selectors/companyFeatureManagementTable.selectors';
import { getSelectedProduct } from '../../core/selectors/product.selectors';
import DrawerPanel from '../DrawerPanel/DrawerPanel';
import { ERROR, SUCCESS, FEATURE_UPDATED } from '../../constants/text';
import { setNotification } from '../../core/actions/notifications.actions';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import FeatureListDrawerManagement from '../FeatureListDrawerManagement/FeatureListDrawerManagement';
import { AppState } from '../../core/reducers/root.reducer';
import GenericError from '../Error/Error';
import { tableTheme } from './companiesUsersTableTheme';
import { formatDate } from '../../core/helpers/dates';

export enum EntityEnum {
    Company= "company",
    User = "user"
}

export interface ICompanyUserManagementProps {

    type: EntityEnum,
    label: string,
    getEntities: (state: AppState) => any,
    getEntityLoading: (state: AppState) => any,
    entityFirstState: any,
    getAllEntitiesRequest: any,
    getEntityFeatures: (state: AppState) => any,
    getEntityFeaturesRequest: any,
    updateEntityFeatures: any,
    columns: GridColumns,
    gridTitle: string,
    tablePageSize?: number,
    rowsPerPage?: number[],

}
const CompanyUserManagement: FunctionComponent<ICompanyUserManagementProps> = ({type, tablePageSize, getAllEntitiesRequest,  ...props}) => {

    const dispatch = useDispatch();
    const selectedProduct = useSelector(getSelectedProduct);
    const entities = useSelector(props.getEntities)
    const isLoading = useSelector(props.getEntityLoading);

    const [selectDrawer, setSelectDrawer] = useState(false)
    const [dialog, setDialog] = useState(false);
    const [messageToConfirm, setMessageToConfirm] = useState('');
    const [selectedEntity, setSelectedEntity] = useState( props.entityFirstState );

    const [filter, setFilter] = useState('');

    const isCompanyFeaturePanelLoading = useSelector(getFeaturesLoadingCompany);
    const isUserFeaturePanelLoading = useSelector(getFeaturesLoadingUser);
    const userFeatureFetchError = useSelector(getfeatureFetchHasErrorUser);
    const companyFeatureFetchError = useSelector(getfeatureFetchHasErrorCompany);

    const isFeaturePanelLoading = type === EntityEnum.Company ? isCompanyFeaturePanelLoading : isUserFeaturePanelLoading
    const featureFetchError = type === EntityEnum.Company ? companyFeatureFetchError : userFeatureFetchError;

    const features = useSelector(props.getEntityFeatures);
    const [newFeatures, setNewFeatures] = useState<Array<any>>(features);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const companiesCount = useSelector(getCompaniesCount)

    useEffect(() => {
        type === EntityEnum.Company?
        dispatch(getAllEntitiesRequest(selectedProduct.id, 1, tablePageSize)):
        dispatch(getAllEntitiesRequest(selectedProduct.id, filter))
    }, [dispatch, selectedProduct.id, getAllEntitiesRequest, type, tablePageSize, filter])

    useEffect(() => {
        setNewFeatures(features)
    }, [features])

    const handleRowClick = (rowData: any) => {
        setSelectedEntity(rowData.row)

        dispatch(props.getEntityFeaturesRequest(selectedProduct.id, rowData.row.id));

        setMessageToConfirm('The features will be updated')
        setSelectDrawer(true)

    }

    const applyFilter = useCallback((e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            setFilter(e.currentTarget.value);
          }
    }, [setFilter]);


    const handleFilterChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value === '') {
            setFilter('');
          }
    }, [setFilter]);

    const handleConfirmYes = async () => {

        setDialog(false)
        setIsConfirmed(true)

        try {
            await props.updateEntityFeatures(selectedProduct?.id, selectedEntity.id, newFeatures);
        } catch (error) {
            dispatch(setNotification(error, ERROR));
        }

        dispatch(setNotification(FEATURE_UPDATED, SUCCESS))
        setSelectDrawer(false);

        setIsConfirmed(false)
    }

    const handleNewFeatures = (newFeature: any) => {
        setNewFeatures(newFeature)
    }

    const handlePageChange = (page: number, details: GridCallbackDetails) => {
        dispatch(getAllEntitiesRequest(selectedProduct.id, page + 1, tablePageSize))
    }


    const handleFormatEntities = entities.map((entity: { startDate: string | Date | null; endDate: string | Date | null; }) => ({
        ...entity,
        startDate: formatDate(entity.startDate),
        endDate: formatDate(entity.endDate)
    }));

    if (featureFetchError) {
        return <GenericError />;
    }

    return (

        <>
        {dialog && <ConfirmDialog
            message={messageToConfirm}
            handleConfirmNo={()=>setDialog(false)}
            handleConfirmYes={handleConfirmYes}
        />}

        <Grid data-testid="entity-management-grid" item xs={12}>
            <h1 className="no-margin-top" data-testid="entity-management-title">{props.gridTitle}</h1>
            {type === EntityEnum.User && <Input placeholder='Filter email' sx={{width: '400px'}} onChange={handleFilterChange} onKeyDown={applyFilter}/>}
            <ThemeProvider theme={tableTheme}>
                <Typography variant="h6">{type === EntityEnum.Company?companiesCount:entities.length} {props.label}</Typography>
                <DataGrid
                    ref={React.createRef()}
                    rows={isLoading ? [] : type === EntityEnum.Company? handleFormatEntities : entities}
                    columns={props.columns}
                    loading={isLoading}
                    onRowClick={handleRowClick}
                    hideFooterSelectedRowCount
                    columnBuffer={props.columns.length}
                    pagination={true}
                    paginationMode={type === EntityEnum.Company?"server":"client"}
                    pageSize={type === EntityEnum.Company?tablePageSize:undefined}
                    onPageChange={type === EntityEnum.Company?handlePageChange:undefined}
                    rowsPerPageOptions={type === EntityEnum.Company?props.rowsPerPage:[25, 50, 100]}
                    rowCount={type === EntityEnum.Company?companiesCount:entities.length}
                />

            </ThemeProvider>
        </Grid>
        {selectDrawer && <DrawerPanel
            anchor="right"
            isOpen={selectDrawer}
            onClose={()=>setSelectDrawer(false)}>
                <FeatureListDrawerManagement
                    features={newFeatures}
                    entity={selectedEntity}
                    error={featureFetchError}
                    onFormSubmit={()=>setDialog(true)}
                    onCancel={()=>setSelectDrawer(false)}
                    featuresLoading={isFeaturePanelLoading}
                    handleNewFeatures={handleNewFeatures}
                    isConfirmed={isConfirmed}
                />
        </DrawerPanel>}
    </>

    );
}

export default CompanyUserManagement;
