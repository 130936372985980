export const GET_USERS = "USERS/GET_USERS";
export interface IGetUsers {
    type: typeof GET_USERS;
    productId: number;
}

export const GET_ALL_USERS = "USERS/GET_ALL_USERS";
export interface IGetAllUsers {
    type: typeof GET_ALL_USERS;
    productId: number;
    filter: string;
}

export const GET_USERS_SUCCESS = "USERS/GET_USERS_SUCCESS";
export interface IGetUsersSuccess {
    type: typeof GET_USERS_SUCCESS;
    users: Array<any>;
}

export const GET_USERS_ERROR = "USERS/GET_USERS_ERROR";
export interface IGetUsersError {
    type: typeof GET_USERS_ERROR;
    error: Error | string;
}

export const ASSIGN_ROLE = "USERS/ASSIGN_ROLE";
export interface IAssignRole {
    type: typeof ASSIGN_ROLE;
    role: any;
    productId: number;
    userId: string;
}

export const ASSIGN_ROLE_SUCCESS = "USERS/ASSIGN_ROLE_SUCCESS";
export interface IAssignRoleRoleSuccess {
    type: typeof ASSIGN_ROLE_SUCCESS;
    user: any;
}

export const GET_USER_FEATURES = "USERS/GET_USER_FEATURES";
export interface IGetUserFeatures {
    type: typeof GET_USER_FEATURES;
    productId: number;
    userId: number
}

export const GET_USER_FEATURES_SUCCESS = "USERS/GET_USER_FEATURES_SUCCESS";
export interface IGetUserFeaturesSuccess {
    type: typeof GET_USER_FEATURES_SUCCESS;
    features: Array<any>;
}

export const GET_USER_FEATURES_ERROR = "USERS/GET_USER_FEATURES_ERROR";
export interface IGetUserFeaturesError {
    type: typeof GET_USER_FEATURES_ERROR;
    error: Error | string;
}


export type UserAction =
    | IGetUsers
    | IGetAllUsers
    | IGetUsersSuccess
    | IGetUsersError
    | IAssignRole
    | IAssignRoleRoleSuccess
    | IGetUserFeatures
    | IGetUserFeaturesSuccess
    | IGetUserFeaturesError;