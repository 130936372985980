import { AppState } from '../reducers/root.reducer';
const emptyProductsList: Array<any> = [];

export const getProducts = (state: AppState): Array<any> => (state.productsData || {}).products || emptyProductsList;
export const getProductsLoading = (state: AppState): boolean => (state.productsData || {}).isLoading;

export const getSelectedProduct = (state: AppState): any => {
    const product = (state.productsData || {}).selectedProduct;

    if (!product) {
        const storedProduct = localStorage.getItem('product');
        return storedProduct !== null ? JSON.parse(storedProduct) : undefined;
    }

    return product;
};