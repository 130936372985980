import React from 'react';
import { FormControlLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { NO_ACCESS, ACCESS, INHERIT } from '../../constants/text';
import { IFeatureManagement } from '../FeatureListDrawerManagement/FeatureListDrawerManagement';


export interface ISelectBoxProps {
    feature: IFeatureManagement;
    handleChange: Function;
}

const SelectBox = (props: ISelectBoxProps) => {

    const feature = props.feature;

    return (
        <FormControlLabel
            sx={{
                margin: 0,
                justifyContent: 'space-between',
            }}
            control={
                <Select
                    labelId="feature-select-label"
                    id="feature-select"
                    data-testid="select-drop-down"
                    value={feature.isEnabled === undefined ? '-1' : feature.isEnabled ? '1' : '0'}
                    onChange={e => props.handleChange(feature.id, e)}
                    style={{ height: 45 }}
                >
                    <MenuItem value={'0'}>{NO_ACCESS}</MenuItem>
                    <MenuItem value={'1'}>{ACCESS}</MenuItem>
                    <MenuItem value={'-1'}>{INHERIT}</MenuItem>
                </Select>
            }
            label={feature.name}
            labelPlacement="start"
        />
    )

};

export default SelectBox;
