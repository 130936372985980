import * as actions from '../actions/actionTypes/users.actionType';

export interface IUsersState {
    productId?: number;
    userId?: string | number,
    users?: Array<any>;
    role?: any;
    userManagementTableLoading: boolean;
    error?: Error | string;
    features: Array<any>;
    areFeaturesLoading: boolean;
    featuresFetchError?: Error | string;    
}

const initialState: IUsersState = {
    users: [],
    productId: undefined,
    role: undefined,
    userManagementTableLoading: false,
    error: undefined,
    features: [],
    areFeaturesLoading: false,
    featuresFetchError: undefined,
};

export default function users(state: IUsersState = initialState, action: actions.UserAction): IUsersState {
    switch (action.type) {
        case actions.GET_USERS:
            return {
                ...state,
                productId: action.productId,
                userManagementTableLoading: true
            };
        case actions.GET_ALL_USERS:
            return {
                 ...state,
                 productId: action.productId,
                 userManagementTableLoading: true
            };
        case actions.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.users,
                userManagementTableLoading: false
            };
        case actions.GET_USERS_ERROR:
            return {
                ...state,
                users: [],
                error: action.error,
                userManagementTableLoading: false
            };
        case actions.ASSIGN_ROLE:
            return {
                ...state,
                role: action.role,
                productId: action.productId,
                userId: action.userId
            };
        case actions.ASSIGN_ROLE_SUCCESS:
            return {
                ...state,
                users: (state.users || []).map((user) => {
                    if (action.user && action.user.roles && user.id === action.user.id) {
                        user.roles = action.user.roles;
                    }

                    return user;
                })
            };
        case actions.GET_USER_FEATURES:
            return {
                ...state,
                features: [],
                productId: action.productId,
                userId: action.userId,
                areFeaturesLoading: true,
                featuresFetchError: undefined
            }
        case actions.GET_USER_FEATURES_SUCCESS:
            return {
                ...state,
                features: action.features,
                areFeaturesLoading: false
            }
        case actions.GET_USER_FEATURES_ERROR:
            return {
                ...state,
                featuresFetchError: action.error,
                areFeaturesLoading: false
            }

        default:
            return state;
    }
}