import { User } from "../../auth0/User";

export const LOGIN_SUCCESS = 'AUTH0_LOGIN_SUCCESS';

export interface IActiveDirectoryData {
    user: User;
}

export interface ILoginSuccess {
    type: typeof LOGIN_SUCCESS;
    payload: IActiveDirectoryData;
}

export type AuthAction = ILoginSuccess;