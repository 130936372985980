import { combineReducers } from 'redux';
import usersReducer from './users.reducer';
import rolesReducer from './roles.reducer';
import productsReducer from './products.reducer';
import authReducer from './auth.reducer';
import companiesReducer from './companies.reducer';
import notificationsReducer from './noticications.reducer';
import featuresReducer from './features.reducer';

const rootReducer = combineReducers({
  usersData: usersReducer,
  rolesData: rolesReducer,
  productsData: productsReducer,
  authData: authReducer,
  companiesData: companiesReducer,
  notificationsData: notificationsReducer,
  featuresData: featuresReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;