import { api } from '../api';

export interface ICompaniesResponse {
    companies: Array<any> | Error;
    page: number;
    totalCount: number
}

export function getCompaniesPaged(productId: number, page: number, pageSize: number) {
    return api.companies.getCompaniesPaged(productId, page, pageSize);
}

export async function getCompanyFeatures(productId: number, companyId: number) {
    return await api.companies.getCompanyFeatures(productId, companyId);
}

export async function updateCompanyFeatures(productId: number, companyId: number, features: any[]) {
    return await api.companies.updateCompanyFeatures(productId, companyId, features);
}