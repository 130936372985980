export const GENERIC_ERROR_MESSAGE = 'An error occurred, please try again later.';
export const UPDATE = 'Update';
export const ADD = 'Add';
export const DELETE = 'Delete';
export const SAVE = 'Save';
export const FEATURE_UPDATED = 'Feature updated';
export const FEATURE_ADDED ='Feature added';
export const FEATURE_DELETED = 'Feature deleted';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const INFO = 'info';
export const WARNING = 'warning';
export const NO_ACCESS = 'No Access';
export const ACCESS = 'Access';
export const INHERIT = 'Inherit';
export const FEAT_MANAGEMENT_COMPANY = 'Feature Management (company level)';
export const FEAT_MANAGEMENT = 'Feature Management';
export const FEAT_MANAGEMENT_USER = 'Feature Management (user level)'