import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel } from 'msal';
import "regenerator-runtime/runtime";

const msalLogsEnabled = ['dev', 'local'].includes(process.env.REACT_APP_API || '');

const systemConfig = msalLogsEnabled ? {
    logger: new Logger(
        (logLevel, message, containsPii) => {
            console.log("[MSAL]", message);
        },
        {
            level: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    )
} : undefined;

export const authProvider = new MsalAuthProvider(
    {
        auth: {
            authority: "https://login.microsoftonline.com/2567d566-604c-408a-8a60-55d0dc9d9d6b",
            clientId: "acca2c70-7ab4-43c0-ba87-8f47b00a7e15",
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
            validateAuthority: true,
            navigateToLoginRequestUrl: true
        },
        system: systemConfig,
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false
        }
    },
    {
        scopes: ["openid"]
    },
    {
        loginType: LoginType.Redirect,
        // When a token is refreshed it will be done by loading a page in an iframe.
        // Rather than reloading the same page, we can point to an empty html file which will prevent
        // site resources from being loaded twice.
        tokenRefreshUri: window.location.origin + "/auth.html"
    }
);
