import React, { FunctionComponent } from 'react';
import Main from '../Main/Main';
import { Typography, Box, Button } from '@mui/material';
import { authProvider } from '../../core/auth/authProvider';

export const Forbidden: FunctionComponent = () => {
    return (
        <Main>
            <Box data-testid="forbidden" display="flex" alignItems="center" flexDirection="column" height="400px">
                <Typography data-testid="oops-text" variant="h3">Oops...</Typography>
                <Typography data-testid="forbidden-text" variant="h3">Forbidden</Typography>
                <Typography data-testid="please-text" variant="body1">Please try to login again or contact an admin.</Typography>
                <Box marginTop="20px">
                    <Button data-testid="login-button" variant="contained" color="inherit" onClick={() => authProvider.login()}>
                        Log in
                    </Button>
                </Box>
            </Box>
        </Main>
    );
}

export default Forbidden;
