import { put, call, takeEvery, all, fork } from 'redux-saga/effects';
import * as actions from '../actions/features.actions';
import * as actionTypes from '../actions/actionTypes/features.actionType';
import { getFeatures } from '../services/features.service';

function* onLoadFeatures({ productId }: actionTypes.IGetFeatures) {
    try {
        const { data } = yield call(getFeatures, productId);
        yield put(actions.getFeaturesSuccess(data));
    } catch (error) {
        console.log(error)
        yield put(actions.getFeaturesError(error?.response?.data?.error || error));
    }
}

function* watchOnLoadFeatures() {
    yield takeEvery(actionTypes.GET_FEATURES, onLoadFeatures);
}

export default function* usersSaga() {
    yield all([
        fork(watchOnLoadFeatures)
    ]);
}
