import { put, call, takeEvery, all, fork } from 'redux-saga/effects';

import { getRoles } from '../services/roles.service';
import * as actions from '../actions/roles.actions';
import * as actionTypes from '../actions/actionTypes/roles.actionType';


function* onGetRoles({ productId }: actionTypes.IGetRoles) {
    try {
        const { data } = yield call(getRoles, productId);
        yield put(actions.getRolesSuccess(data));
    } catch (error) {
        yield put(actions.getRolesError(error.response.data.error));
    }
}

function* watchOnGetRoles() {
    yield takeEvery(actionTypes.GET_ROLES, onGetRoles);
}

export default function* usersSaga() {
    yield all([
        fork(watchOnGetRoles)
    ]);
}
