import * as actions from './actionTypes/products.actionType';

export function getProductsRequest(): actions.IGetProducts {
    return {
        type: actions.GET_PRODUCTS
    };
}

export function getProductsSuccess(products: Array<any>): actions.IGetProductsSuccess {
    return {
        type: actions.GET_PRODUCTS_SUCCESS,
        products
    };
}

export function getProductsError(error: Error | string): actions.IGetProductsError {
    return {
        type: actions.GET_PRODUCTS_ERROR,
        error
    };
}


export function setProductSelection(product: any): actions.ISelectedProduct {
    return {
        type: actions.SET_SELECTED_PRODUCT,
        product
    };
}