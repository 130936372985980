import * as actions from './actionTypes/roles.actionType';

export function getRolesRequest(productId: number): actions.IGetRoles {
    return {
        type: actions.GET_ROLES,
        productId
    };
}

export function getRolesSuccess(roles: Array<any>): actions.IGetRolesSuccess {
    return {
        type: actions.GET_ROLES_SUCCESS,
        roles
    };
}

export function getRolesError(error: Error | string): actions.IGetRolesError {
    return {
        type: actions.GET_ROLES_ERROR,
        error
    };
}
