import React, { FunctionComponent, useState, useEffect } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import {
    Button,
    CircularProgress,
    Typography,
    FormControlLabel,
    FormGroup,
    Checkbox,
    TextField
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Polyline } from '@mui/icons-material';
import styles from './FeatureManagementDrawer.module.scss';
import { ADD, UPDATE, SAVE} from '../../constants/text';


export interface FeatureRow {
    id: number;
    name: string;
    isActive: boolean;
    allowAllUsers: boolean;
    disableForTrialLicense?: boolean;
}

export interface IFeatureManagementDrawerProps {
    feature: FeatureRow;
    onFormSubmit: (data: any) => any;
    onCancel: () => void;
    addNewFeature:boolean;
    handleSelectedFeature: (feature: FeatureRow) => void
    isConfirmed: boolean;
}

const FeatureManagementDrawer: FunctionComponent<IFeatureManagementDrawerProps> = (props) => {
    const [feature, setFeature] = useState<FeatureRow>(props.feature);
    const [isSaving, setIsSaving] = useState<boolean>(false);

     useEffect(() => {
         setFeature(props.feature);
     }, [props.feature])

    const handleChange = (field: string, checked: boolean) => {
         const modifiedFeature = {...feature, [field]:checked};
         props.handleSelectedFeature(modifiedFeature)
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isSaving) {
            setIsSaving(true);
            await props.onFormSubmit(feature);
        }

        setIsSaving(false);
    };

    const handleAddNameFeature = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const modifiedFeature = {...feature, name:e.target.value};
        props.handleSelectedFeature(modifiedFeature)
    }

    return (
        <form name="selectedFeatureForm"
            noValidate
            onSubmit={handleSubmit}
            className={styles.form}
            data-testid="selectedFeature-form"
        >
            <Typography
                variant="h5"
                gutterBottom
                align="center"
                data-testid="addUpdateFeature-name"
            >
               {props.addNewFeature?ADD:UPDATE} Feature {!props.addNewFeature?`(${feature.name})`:''}
            </Typography>
            <div className={styles.table}>
                 <div className={styles.heading}>Feature Settings</div>

                 <FormGroup className={styles.checkboxList}>
                        <div className={styles.checkbox}>
                            {props.addNewFeature && <TextField
                                id="input-with-icon-textfield"
                                label="Feature Name Required"
                                required
                                onChange={(e) => handleAddNameFeature(e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <Polyline />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                value={feature.name}
                                data-testid ="feature-new-name"
                            />}
                            <FormControlLabel
                                sx={{
                                    margin: 0,
                                    justifyContent: 'space-between'
                                }}
                                control={<Checkbox
                                    color="secondary"
                                    checked={feature.isActive}
                                    onChange={(e, checked) => handleChange('isActive', checked)}
                                    name={`checkbox-${feature.id}-isActive`}
                                    />}
                                label="Active"
                                labelPlacement="start"
                                disabled={props.addNewFeature?true:false}
                            />
                            <FormControlLabel
                                sx={{
                                    margin: 0,
                                    justifyContent: 'space-between'
                                }}
                                control={<Checkbox
                                    color="secondary"
                                    checked={feature.allowAllUsers}
                                    onChange={(e, checked) => handleChange('allowAllUsers', checked)}
                                    name={`checkbox-${feature.id}-allowAllUsers`} 
                                    />}
                                label="Allow All Users"
                                labelPlacement="start"
                                disabled={props.addNewFeature?true:false}                         
                            />
                            <FormControlLabel
                                sx={{
                                    margin: 0,
                                    justifyContent: 'space-between'
                                }}
                                control={<Checkbox
                                    color="secondary"
                                    checked={feature.disableForTrialLicense}
                                    onChange={(e, checked) => handleChange('disableForTrialLicense', checked)}
                                    name={`checkbox-${feature.id}-disableForTrialLicense`} 
                                    />}
                                label="Disable Trial License"
                                labelPlacement="start"
                                disabled={props.addNewFeature?true:false}
                            />
                        </div>
                </FormGroup>
            </div>

            <Button
                variant="contained"
                color="primary"
                type="submit"
                data-testid="save-btn"
                startIcon={isSaving || props.isConfirmed ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
            >
                {props.addNewFeature?SAVE:UPDATE}
            </Button>
            <Button
                color="inherit"
                variant="contained"
                onClick={props.onCancel}
                data-testid="cancel-btn"
            >
                Cancel
            </Button>

        </form>
    );
};

export default FeatureManagementDrawer;
