import * as actions from '../actions/actionTypes/products.actionType';

export interface IProductsState {
    products?: any;
    error?: Error | string;
    isLoading: boolean;
    selectedProduct?: any;
}

const initialState: IProductsState = { products: [], error: undefined, isLoading: false, selectedProduct: undefined };

export default function products(state: IProductsState = initialState, action: actions.ProductAction): IProductsState {
    switch (action.type) {
        case actions.GET_PRODUCTS:
            localStorage.removeItem('product');

            return {
                ...state,
                products: [],
                isLoading: true,
                selectedProduct: undefined
            }
        case actions.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.products,
                isLoading: false
            };
        case actions.GET_PRODUCTS_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case actions.SET_SELECTED_PRODUCT:
            if (action.product) {
                localStorage.setItem('product', JSON.stringify(action.product));
            } else {
                localStorage.removeItem('product');
            }

            return {
                ...state,
                selectedProduct: action.product
            }
        default:
            return state;
    }
}