import { AppState } from '../reducers/root.reducer';

const emptyCompaniesList: Array<any> = [];
const emptyFeaturesList: Array<any> = [];

export const getCompanies = (state: AppState): Array<any> => (state.companiesData || {}).companies || emptyCompaniesList;
export const getCompaniesCount = (state: AppState): number => (state.companiesData || {}).companiesCount || 0;
export const tableFetchHasError = (state: AppState): boolean => !!state.companiesData?.companiesFetchError;
export const getIsTableLoading = (state: AppState): boolean => (state.companiesData || {}).isTableLoading;

export const getCompanyFeatures = (state: AppState): Array<any> => (state.companiesData || {}).features || emptyFeaturesList;
export const getFeaturesLoading = (state: AppState): boolean => (state.companiesData || {}).areFeaturesLoading;
export const featureFetchHasError = (state: AppState): boolean => !!state.companiesData?.featuresFetchError;

export const updateFeaturesInProgress = (state: AppState): boolean => (state.companiesData || {}).updatingFeaturesInProgress;
export const updateFeaturesSuccess = (state: AppState): boolean => (state.companiesData || {}).updatingFeaturesInProgress;
export const updateFeaturesError = (state: AppState): boolean => (state.companiesData || {}).updatingFeaturesInProgress;





