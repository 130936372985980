import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { PeopleAlt, Polyline } from '@mui/icons-material';
import { FEAT_MANAGEMENT_COMPANY, FEAT_MANAGEMENT_USER, FEAT_MANAGEMENT } from '../../constants/text';

export const MenuItems: FunctionComponent = () =>
  (
    <div data-testid="menu-items">
      <Divider />
      <List>
        <ListItem button component={Link} to='/'>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem> 
        <ListItem button component={Link} to='/feature-management'>
          <ListItemIcon>
            <Polyline />
          </ListItemIcon>
          <ListItemText primary={FEAT_MANAGEMENT} />
        </ListItem>
        <ListItem button component={Link} to='/company-feature-management'>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary={FEAT_MANAGEMENT_COMPANY} />
        </ListItem>
        <ListItem button component={Link} to='/user-feature-management'>
          <ListItemIcon>
            <PeopleAlt />
          </ListItemIcon>
          <ListItemText primary={FEAT_MANAGEMENT_USER} />
        </ListItem>
      </List>
      <Divider />
    </div>
  );