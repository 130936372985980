import { AlertColor } from '@mui/material';
import * as actions from './actionTypes/notifications.actionTypes';

export function setNotification(message?: string, severity?: AlertColor): actions.ISetNotification {
    return {
        type: actions.SET_NOTIFICATION,
        message,
        severity
    };
}

export function clearNotification(): actions.ISetNotification {
    return {
        type: actions.SET_NOTIFICATION,
        message: undefined,
        severity: undefined,
    };
}