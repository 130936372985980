import React, { FunctionComponent } from 'react';
import { getAreUsersLoading, getAllUsers, getUserFeatures } from '../../core/selectors/users.selector';
import { getAllUsersLoading, getUserFeaturesRequest } from '../../core/actions/users.actions';
import { updateUserFeatures } from '../../core/services/users.service';
import CompanyUserManagement, { EntityEnum } from '../CompanyUserManagement/CompanyUserManagement';
import { GridColumns } from '@mui/x-data-grid';
import { FEAT_MANAGEMENT_USER } from '../../constants/text';

export interface IUserFeatureManagementProps { }

const UserFeatureManagement: FunctionComponent<IUserFeatureManagementProps> = (props) => {

    const columns: GridColumns =
        [
            {
                headerName: 'User ID',
                field: 'id',
                flex: 1.5,
                disableColumnMenu: true,
                type: 'string'
            },
            {
                headerName: 'Display Name',
                field: 'displayName',
                flex: 1.5,
                disableColumnMenu: true,
                type: 'string',
            },
            {
                headerName: 'Email',
                field: 'email',
                flex: 1.5,
                disableColumnMenu: true,
                type: 'string',
            },
            {
                headerName: 'Organization ID',
                field: 'organizationId',
                flex: 1.5,
                disableColumnMenu: true,
                type: 'string',
            },
            {
                headerName: 'Organization name',
                field: 'organizationName',
                flex: 1.5,
                disableColumnMenu: true,
                type: 'string',
            },
        ];

    return (
        <CompanyUserManagement 
            type={EntityEnum.User}
            label={"users"} 
            getEntities={getAllUsers} 
            getEntityLoading={getAreUsersLoading}
            entityFirstState={{ id: 0, displayName: '', email: '', organizationId: '', organizationName: '' }}
            getAllEntitiesRequest={getAllUsersLoading}
            getEntityFeatures={getUserFeatures}
            getEntityFeaturesRequest={getUserFeaturesRequest}
            updateEntityFeatures={updateUserFeatures}
            columns={columns} 
            gridTitle={FEAT_MANAGEMENT_USER}
        />
    );
};

export default UserFeatureManagement;