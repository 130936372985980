import * as actions from '../actions/actionTypes/auth.actionType';
import { User } from '../auth0/User';

export interface IAuthState {
    user?: User;
}

const initialState: IAuthState = {
    user: undefined
};

export default function auth(state: IAuthState = initialState, action: actions.AuthAction): IAuthState {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload.user
            };
        default:
            return state;
    }
}