import * as actions from '../actions/actionTypes/companies.actionType';

export interface ICompaniesState {
    productId?: number;
    companyId?: number;
    isTableLoading: boolean;
    areFeaturesLoading: boolean;
    companiesFetchError?: Error | string;
    featuresFetchError?: Error | string;
    updateError?: Error | string;
    companiesCount: number;
    companies: Array<any>;
    features: Array<any>;
    updatingFeaturesInProgress: boolean;
}

const initialState: ICompaniesState = {
    productId: undefined,
    companyId: undefined,
    isTableLoading: false,
    areFeaturesLoading: false,
    companiesFetchError: undefined,
    featuresFetchError: undefined,
    updateError: undefined,
    companiesCount: 0,
    companies: [],
    features: [],
    updatingFeaturesInProgress: false
};

export default function companies(state: ICompaniesState = initialState, action: actions.CompaniesAction): ICompaniesState {
    switch (action.type) {
        case actions.GET_COMPANIES:
            return {
                ...state,
                productId: action.productId,
                companies: [],
                isTableLoading: true,
                companiesFetchError: undefined
            };
        case actions.GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.companies,
                companiesCount: action.count,
                isTableLoading: false
            };
        case actions.GET_COMPANIES_ERROR:
            return {
                ...state,
                companies: [],
                companiesFetchError: action.error,
                isTableLoading: false
            };
        case actions.GET_COMPANY_FEATURES:
            return {
                ...state,
                features: [],
                productId: action.productId,
                companyId: action.companyId,
                areFeaturesLoading: true,
                featuresFetchError: undefined
            }
        case actions.GET_COMPANY_FEATURES_SUCCESS:
            return {
                ...state,
                features: action.features,
                areFeaturesLoading: false
            }
        case actions.GET_COMPANY_FEATURES_ERROR:
            return {
                ...state,
                featuresFetchError: action.error,
                areFeaturesLoading: false
            }
        default:
            return state;
    }
}