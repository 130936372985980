import React from 'react';
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { getSelectedProduct } from '../../core/selectors/product.selectors';

export const ProductSelectionRoute = ({ element, children, ...rest }: any) => {
    const selectedProduct = useSelector(getSelectedProduct);

    if (selectedProduct) {
        return children;
    }

    return <Navigate to='/product-selection' />;
};

export default ProductSelectionRoute;