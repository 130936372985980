import { AlertColor } from '@mui/material';
import * as actions from '../actions/actionTypes/notifications.actionTypes';

export interface INotificationsState {
    message?: string;
    severity?: AlertColor | undefined;
}

const initialState: INotificationsState = {
    message: undefined,
    severity: undefined,
};

export default function notifications(state: INotificationsState = initialState, action: actions.NotificationAction): INotificationsState {
    switch (action.type) {
        case actions.SET_NOTIFICATION:
            return {
                ...state,
                message: action.message,
                severity: action.severity
            };
        default:
            return state;
    }
}