import * as actions from './actionTypes/features.actionType';

export function getFeaturesRequest(productId: number): actions.IGetFeatures {
    return {
        type: actions.GET_FEATURES,
        productId
    };
}

export function getFeaturesSuccess(features: Array<any>): actions.IGetFeaturesSuccess {
    return {
        type: actions.GET_FEATURES_SUCCESS,
        features,
    };
}

export function getFeaturesError(error: Error | string): actions.IGetFeaturesError {
    return {
        type: actions.GET_FEATURES_ERROR,
        error
    };
}