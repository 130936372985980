export const GET_FEATURES = "FEATURES/GET_FEATURES";
export interface IGetFeatures {
    type: typeof GET_FEATURES;
    productId: number;
}

export const GET_FEATURES_SUCCESS = "FEATURES/GET_FEATURES_SUCCESS";
export interface IGetFeaturesSuccess {
    type: typeof GET_FEATURES_SUCCESS;
    features: Array<any>;
}

export const GET_FEATURES_ERROR = "FEATURES/GET_FEATURES_ERROR";
export interface IGetFeaturesError {
    type: typeof GET_FEATURES_ERROR;
    error: Error | string;
}


export type FeaturesAction =
    | IGetFeatures
    | IGetFeaturesSuccess
    | IGetFeaturesError
    