import React, { FunctionComponent } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export interface IConfirmDialogProps {
    message?: string;
    handleConfirmNo: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    handleConfirmYes: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props) => {

    const noButtonRef = React.useRef<HTMLButtonElement>(null);

    return (
        <div data-testid="confirm-dialog">
            <Dialog
                maxWidth="xs"
                open={true}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent dividers>
                    {props.message}
                </DialogContent>
                <DialogActions>
                    <Button data-testid="confirm-no" ref={noButtonRef} onClick={(e) => props.handleConfirmNo(e)}>
                        No
                    </Button>
                    <Button  data-testid="confirm-yes" onClick={(e) => props.handleConfirmYes(e)}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConfirmDialog;