import { put, call, takeEvery, all, fork } from 'redux-saga/effects';

import { getProducts } from '../services/products.service';
import * as actions from '../actions/products.actions';
import * as actionTypes from '../actions/actionTypes/products.actionType';


function* onGetProducts() {
    try {
        const { data } = yield call(getProducts);
        yield put(actions.getProductsSuccess(data));
    } catch (error) {
        yield put(actions.getProductsError(error.response.data.error));
    }
}

function* watchOnGetProducts() {
    yield takeEvery(actionTypes.GET_PRODUCTS, onGetProducts);
}

export default function* usersSaga() {
    yield all([
        fork(watchOnGetProducts)
    ]);
}
