import * as actions from './actionTypes/users.actionType';

export function getUsersLoading(productId: number): actions.IGetUsers {
    return {
        type: actions.GET_USERS,
        productId
    };
}

export function getAllUsersLoading(productId: number, filter: string): actions.IGetAllUsers {
    return {
        type: actions.GET_ALL_USERS,
        productId,
        filter
    };
}


export function getUsersSuccess(users: Array<any>): actions.IGetUsersSuccess {
    return {
        type: actions.GET_USERS_SUCCESS,
        users
    };
}

export function getUsersError(error: Error | string): actions.IGetUsersError {
    return {
        type: actions.GET_USERS_ERROR,
        error
    };
}

export function assignUserRole(role: any, userId: string, productId: number): actions.IAssignRole {
    return {
        type: actions.ASSIGN_ROLE,
        role,
        userId,
        productId
    };
}

export function assignRoleSuccess(user: any): actions.IAssignRoleRoleSuccess {
    return {
        type: actions.ASSIGN_ROLE_SUCCESS,
        user
    };
}

export function getUserFeaturesRequest(productId: number, userId: number): actions.IGetUserFeatures {
    return {
        type: actions.GET_USER_FEATURES,
        productId,
        userId
    };
}

export function getUserFeaturesSuccess(features: Array<any>): actions.IGetUserFeaturesSuccess {
    return {
        type: actions.GET_USER_FEATURES_SUCCESS,
        features
    };
}

export function getUserFeaturesError(error: Error | string): actions.IGetUserFeaturesError {
    return {
        type: actions.GET_USER_FEATURES_ERROR,
        error
    };
}
