import { FeatureRow } from '../../components/FeatureManagementDrawer/FeatureManagementDrawer';
import { api } from '../api';

export interface IFeaturesResponse {
    features: Array<any> | Error;
}

export async function getFeatures(productId: number) {
    return await api.features.getFeatures(productId);
}

export async function addFeature(productId: number, feature: FeatureRow) {
    return await api.features.addFeature(productId, feature);
}

export async function updateFeature(productId: number, featureId: number, feature: FeatureRow) {
    return await api.features.updateFeature(productId, featureId, feature);
}

export async function deleteFeature(productId: number, featuresId: number) {
    return await api.features.deleteFeature(productId, featuresId);
}


