import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import CompanyFeatureManagement from "./components/CompanyFeatureManagement/CompanyFeatureManagement";
import FeatureManagement from "./components/FeatureManagement/FeatureManagement";
import ProductSelectionRoute from "./components/ProductSelectionRoute/ProductSelectionRoute";
import UserFeatureManagement from "./components/UserFeatureManagement/UserFeatureManagement";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Forbidden from "./components/AuthComponents/Forbidden";
import BaseLayout from "./components/BaseLayout/BaseLayout";

const Home = withAuthenticationRequired(lazy(() => import("./components/Dashboard/Dashboard")));
const ProductSelection = withAuthenticationRequired(lazy(
  () => import("./components/ProductSelection/ProductSelection")
));
const ProductSelectionRouteWithAuth = withAuthenticationRequired(ProductSelectionRoute);

const App = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Home />} />
          <Route
            path="/company-feature-management"
            element={
              <ProductSelectionRouteWithAuth>
                <CompanyFeatureManagement />
              </ProductSelectionRouteWithAuth>
            }
          />
          <Route path="/product-selection" element={<ProductSelection />} />
          <Route
            path="/feature-management"
            element={
              <ProductSelectionRouteWithAuth>
                <FeatureManagement />
              </ProductSelectionRouteWithAuth>
            }
          />
          <Route
            path="/user-feature-management"
            element={
              <ProductSelectionRouteWithAuth>
                <UserFeatureManagement />
              </ProductSelectionRouteWithAuth>
            }
          />
        </Route>
        <Route path="forbidden" element={<Forbidden />} />
      </Routes>
    </Suspense>
  );
};

export default App;
