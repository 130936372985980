import React, { FunctionComponent, useEffect, useState } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import styles from './FeatureManagement.module.scss';
import { DataGrid, GridColumns, GridActionsCellItem } from '@mui/x-data-grid';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getAreFeaturesLoading, getFeatures } from '../../core/selectors/features.selector';
import { getFeaturesRequest } from '../../core/actions/features.actions';
import { getSelectedProduct } from '../../core/selectors/product.selectors';
import DrawerPanel from '../DrawerPanel/DrawerPanel';
import FeatureManagementDrawer, { FeatureRow } from '../FeatureManagementDrawer/FeatureManagementDrawer';
import { UPDATE, ADD, DELETE, FEATURE_ADDED, FEATURE_DELETED, FEATURE_UPDATED, ERROR, WARNING, SUCCESS } from '../../constants/text';
import { setNotification } from '../../core/actions/notifications.actions';
import { addFeature, updateFeature, deleteFeature } from '../../core/services/features.service';
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { LoadingButton } from '@mui/lab'
import { tableTheme } from '../CompanyUserManagement/companiesUsersTableTheme';

export interface IFeatureManagementProps { }

const FeatureManagement: FunctionComponent<IFeatureManagementProps> = (props) => {

    const dispatch = useDispatch();
    const initialFeature: FeatureRow = { id: -1, name: '', isActive: false, allowAllUsers: false, disableForTrialLicense: false }
    const selectedProduct = useSelector(getSelectedProduct);
    const features = useSelector(getFeatures)
    const isLoading = useSelector(getAreFeaturesLoading);

    const [selectDrawer, setSelectDrawer] = useState(false)
    const [selectedFeature, setSelectedFeature] = useState(initialFeature);
    const [addUpdateDeleteFeature, setAddUpdateDeleteFeature] = useState(UPDATE);
    const [dialog, setDialog] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [messageToConfirm, setMessageToConfirm] = useState('');

    useEffect(() => {
        dispatch(getFeaturesRequest(selectedProduct.id));
    }, [dispatch, selectedProduct.id])

    const deleteSelectedFeatureGrid = React.useCallback(
        (row) => () => {
            setSelectedFeature(row)
            setAddUpdateDeleteFeature(DELETE)
            setMessageToConfirm(`The feature '${row.name}' will be deleted`)
            setDialog(true)
        },
        [],
    );

    const handleDeleteIcon = (row: any) => {
        if (selectedFeature?.id && selectedFeature.id && row.id === selectedFeature.id)
            return <LoadingButton variant='text' loadingPosition='start' startIcon={<DeleteIcon />} loading={isConfirmed} />
        else
            return <LoadingButton variant='text' loadingPosition='start' startIcon={<DeleteIcon />} loading={false} />
    }

    // @ts-ignore
    // @ts-ignore
    const columns: GridColumns =
        [
            {
                headerName: 'Feature Name',
                field: 'name',
                flex: 1.5,
                disableColumnMenu: true,
                type: 'string'
            },
            {
                headerName: 'Active',
                field: 'isActive',
                flex: 1.5,
                disableColumnMenu: true,
                type: 'boolean',
            },
            {
                headerName: 'Allow All Users',
                field: 'allowAllUsers',
                flex: 1.5,
                disableColumnMenu: true,
                type: 'boolean',
            },
            {
                headerName: 'Disable Trial License',
                field: 'disableForTrialLicense',
                flex: 1.5,
                disableColumnMenu: true,
                type: 'boolean',
            },
            {
                headerName: '',
                field: 'deleteButton',
                disableColumnMenu: true,
                sortable: false,
                headerClassName: styles.headerCenter,
                type: 'actions',
                getActions: (params) => [
                    // @ts-ignore
                    <GridActionsCellItem
                        icon={handleDeleteIcon(params.row)}
                        label="Delete"
                        onClick={deleteSelectedFeatureGrid(params.row)}
                        data-testid="trash"
                    />,
                ]

            },

        ];

    const handleRowClick = (rowData: any) => {
        setSelectedFeature(rowData.row)
        setAddUpdateDeleteFeature(UPDATE)
        setMessageToConfirm('The feature will be updated')
        setSelectDrawer(true)
    }

    const handleAddFeature = () => {
        setSelectedFeature(initialFeature)
        setAddUpdateDeleteFeature(ADD)
        setMessageToConfirm('The feature will be added')
        setSelectDrawer(true)
    }

    const handleSelectedFeature = (feature: FeatureRow) => {
        setSelectedFeature(feature)
    }

    const handleConfirmYes = async () => {

        setDialog(false)
        setIsConfirmed(true)
        let notified: string = '';

        try {
            if (addUpdateDeleteFeature === DELETE) {
                await deleteFeature(selectedProduct.id, selectedFeature.id)
                notified = FEATURE_DELETED
            }
            else {
                if (addUpdateDeleteFeature === ADD && selectedFeature.name === '') {
                    dispatch(setNotification("Feature's name required", WARNING))
                    setIsConfirmed(false)
                    return
                }

                addUpdateDeleteFeature === ADD ? await addFeature(selectedProduct.id, selectedFeature) : await updateFeature(selectedProduct.id, selectedFeature.id, selectedFeature);
                notified = addUpdateDeleteFeature === ADD ? FEATURE_ADDED : FEATURE_UPDATED
            }
        }
        catch (error) {
            dispatch(setNotification(error, ERROR));
            setIsConfirmed(false)
            return
        }

        dispatch(setNotification(notified, SUCCESS))
        dispatch(getFeaturesRequest(selectedProduct.id));
        setSelectDrawer(false)
        setIsConfirmed(false)
    }

    return (
        <>
            {dialog && <ConfirmDialog
                message={messageToConfirm}
                handleConfirmNo={() => setDialog(false)}
                handleConfirmYes={handleConfirmYes}
            />}
            <Grid data-testid="feature-management-grid" item xs={12}>
                <h1 className="no-margin-top" data-testid="feature-management-title">Feature management</h1>
                <ThemeProvider theme={tableTheme}>
                    <div className={styles.headerFeature}>
                        <Typography variant="h6">{features.length} features</Typography>
                        <Button
                            sx={{ marginLeft: "auto" }}
                            color="primary"
                            variant="contained"
                            onClick={handleAddFeature}
                            data-testid="feature-management-add-btn">
                            Add Feature
                        </Button>
                    </div>
                    <DataGrid
                        ref={React.createRef()}
                        rows={features}
                        columns={columns}
                        loading={isLoading}
                        onRowClick={handleRowClick}
                        hideFooterSelectedRowCount
                        columnBuffer={columns.length}
                    />
                </ThemeProvider>
            </Grid>
            {selectDrawer && <DrawerPanel
                anchor="right"
                isOpen={selectDrawer}
                onClose={() => setSelectDrawer(false)}>
                <div className={styles.rightDrawerContent}>
                    <FeatureManagementDrawer
                        feature={selectedFeature}
                        onFormSubmit={() => setDialog(true)}
                        onCancel={() => setSelectDrawer(false)}
                        addNewFeature={addUpdateDeleteFeature === ADD ? true : false}
                        handleSelectedFeature={handleSelectedFeature}
                        isConfirmed={isConfirmed}
                    />
                </div>
            </DrawerPanel>}
        </>
    );
};

export default FeatureManagement;
