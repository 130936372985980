export const GET_COMPANIES = "COMPANIES/GET_COMPANIES";
export interface IGetCompanies {
    type: typeof GET_COMPANIES;
    productId: number;
    page: number;
    pageSize: number;
}

export const GET_COMPANIES_SUCCESS = "COMPANIES/GET_COMPANIES_SUCCESS";
export interface IGetCompaniesSuccess {
    type: typeof GET_COMPANIES_SUCCESS;
    companies: Array<any>;
    count: number;
}

export const GET_COMPANIES_ERROR = "COMPANIES/GET_COMPANIES_ERROR";
export interface IGetCompaniesError {
    type: typeof GET_COMPANIES_ERROR;
    error: Error | string;
}

export const GET_COMPANY_FEATURES = "COMPANIES/GET_COMPANY_FEATURES";
export interface IGetCompanyFeatures {
    type: typeof GET_COMPANY_FEATURES;
    productId: number;
    companyId: number
}

export const GET_COMPANY_FEATURES_SUCCESS = "COMPANIES/GET_COMPANY_FEATURES_SUCCESS";
export interface IGetCompanyFeaturesSuccess {
    type: typeof GET_COMPANY_FEATURES_SUCCESS;
    features: Array<any>;
}

export const GET_COMPANY_FEATURES_ERROR = "COMPANIES/GET_COMPANY_FEATURES_ERROR";
export interface IGetCompanyFeaturesError {
    type: typeof GET_COMPANY_FEATURES_ERROR;
    error: Error | string;
}

export type CompaniesAction =
    | IGetCompanies
    | IGetCompaniesSuccess
    | IGetCompaniesError
    | IGetCompanyFeatures
    | IGetCompanyFeaturesSuccess
    | IGetCompanyFeaturesError;