import { AlertColor } from "@mui/material";

export const SET_NOTIFICATION = "NOTIFICATION/SET";
export interface ISetNotification {
    type: typeof SET_NOTIFICATION;
    message?: string;
    severity?: AlertColor | undefined;
}

export type NotificationAction =
    | ISetNotification;