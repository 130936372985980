import React, { FunctionComponent, useEffect } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { getNotificationMessage } from '../../core/selectors/notifications.selections';
import { clearNotification } from '../../core/actions/notifications.actions';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { INotificationsState } from '../../core/reducers/noticications.reducer';


const SnackBarNotification: FunctionComponent = () => {
    const notificationMessage: INotificationsState = useSelector(getNotificationMessage);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState<boolean>(false);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

    useEffect(() => {
        setOpen(!!notificationMessage.message)
    }, [notificationMessage.message])

    const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
        dispatch(clearNotification());
        setOpen(false);
    };

    return <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={open}
        onClose={handleClose}
        data-testid="alert">
        <Alert onClose={handleClose} severity={notificationMessage.severity} sx={{ width: '100%' }}>
             {notificationMessage.message}
        </Alert>
    </Snackbar>
};

export default SnackBarNotification;
