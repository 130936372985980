import { CORE_COLORS } from "../../constants/colors";
import { createTheme } from '@mui/material/styles';

export const tableTheme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    height: '650px',
                    width: '100%',
                    backgroundColor: 'white'
                },
                row: {
                    '&:hover': {
                        cursor: 'pointer'
                    }
                },
                cell: {
                    '&:focus': {
                        outline: 'none'
                    }
                },
                footerContainer: {
                    height: '60px'
                },
                columnHeaders: {
                    backgroundColor: CORE_COLORS.PURPLE1
                },
                columnHeaderTitle: {
                    color: 'white',
                    fontWeight: '700'
                },
                columnSeparator: {
                    color: 'white'
                }
            }
        }
    }
});