export const GET_ROLES = "ROLES/GET_ROLES";
export interface IGetRoles {
    type: typeof GET_ROLES;
    productId: number;
}

export const GET_ROLES_SUCCESS = "ROLES/GET_ROLES_SUCCESS";
export interface IGetRolesSuccess {
    type: typeof GET_ROLES_SUCCESS;
    roles: Array<any>;
}

export const GET_ROLES_ERROR = "ROLES/GET_ROLES_ERROR";
export interface IGetRolesError {
    type: typeof GET_ROLES_ERROR;
    error: Error | string;
}

export type RoleAction =
    | IGetRoles
    | IGetRolesError
    | IGetRolesSuccess;