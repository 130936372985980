import { createTheme, Theme } from '@mui/material/styles';
import { CORE_COLORS } from '../constants/colors';

export const themeLight: Theme = createTheme({
    palette: {
        background: {
            default: "#f5f6fa"
        },
        primary: {
            main: CORE_COLORS.PRIMARY
        },
        secondary: {
            main: CORE_COLORS.PURPLE1
        }
    },
    typography: {
        fontFamily: [
            '\'FSMe\'',
            'helvetica',
            'sans-serif'
        ].join(',')
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: CORE_COLORS.GREY2,
                    color: 'white'
                }
            }
        }
    }
});
