/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { createBrowserHistory } from "history";
import * as serviceWorker from "./serviceWorker";
import { themeLight } from "./components/MaterialUITheme";
import configureStore from "./core/store/configure-store";

import "./index.scss";
import { CustomRouter } from "./CustomRouter";
import { Auth0Provider } from "@auth0/auth0-react";
import { authorizationConfig } from "./core/auth0/authorizationConfig";
import App from "./App";

const history = createBrowserHistory();
const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeLight}>
      <Provider store={store}>
        <Auth0Provider {...authorizationConfig}>
          <CustomRouter history={history}>
            <App />
          </CustomRouter>
        </Auth0Provider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
